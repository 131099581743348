import Image from "next/legacy/image";
import SectionHeader from '../components/SectionHeader/SectionHeader';

const Error = () => {
  const gifLoader = () => {
    const gif = Math.random() < 0.01 ? 'it.gif' : 'monnyd.gif';
    return `https://res.cloudinary.com/blank/blankno/${gif}`;
  };

  return (
    <>
      <div className="w-screen h-screen relative">
        <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] z-10">
          <SectionHeader
            setDefaultColor={false}
            variant={'respiraLarge'}
            className="children:bg-transparent"
          >
            404
          </SectionHeader>
        </div>
        <Image
          loader={gifLoader}
          src={gifLoader()}
          layout="fill"
          alt="fail"
          objectFit="cover"
          unoptimized={true}
        />
      </div>
    </>
  );
};

export default Error;
